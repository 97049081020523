import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Dashboard from '../../../components/Admin/Dashboard.js';
import { useState, useEffect } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { Styles, StylesForm } from '../../../styles/global.js';
import {
  getCourseDistribution,
  getDepartmentDistribution,
  getMainConfig,
  getProjects,
  getStats,
  getUsers,
  objectToQueryString,
} from '../../../utils/API.js';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import Modal from '../../../components/Modal.js';
import Accordion from '../../../components/CompletionAccordians.js';
import DepartmentAccordion from '../../../components/DepartmentAccordians.js';
import CourseAccordion from '../../../components/CourseAccordians.js';

import { Typography, Divider, Button } from '@mui/material';
import { formatDate } from '../../../utils/dates.js';

// Displays Project Count Distribution by Department
const CourseStatsWidget = () => {
  const [open, setOpen] = useState(false);
  const [distribution, setDistribution] = useState({
    SD1: 0,
    SD2: 0,
  });
  const [SD1, setSD1] = useState([]);
  const [SD2, setSD2] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const fetchData = async () => {
    try {
      const year = searchParams.get('year') || new Date().getFullYear();

      const response = await getCourseDistribution(year);

      const temp = {};
      response?.data?.data?.forEach((element) => {
        temp[element._id] = element.totalProjects;
      });

      setDistribution(temp);

      const projects = await getProjects(objectToQueryString({ year }));
      setSD1(
        projects?.data?.data?.filter((project) => project?.course === 'SD1') ||
          []
      );
      setSD2(
        projects?.data?.data?.filter((project) => project?.course === 'SD2') ||
          []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [searchParams]);
  const data = {
    labels: ['SD1', 'SD2'],
    datasets: [
      {
        label: 'Course Project Count',
        data: [distribution?.SD1 || 0, distribution?.SD2 || 0],
        backgroundColor: ['rgba(250, 250, 250, 0.2)', 'rgba(0, 0, 0, 0.2)'],
        borderColor: ['rgba(215, 215, 215, 1)', 'rgba(0, 0, 0, 1'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Course Project Distribution */}
      <Typography {...StylesForm.text('h6')}>
        Course Project Distribution
      </Typography>
      <Divider />

      {/* Start of Pie Chart: Course Project Distributions*/}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} />
        </Box>
      </Box>

      {/* Detailed Distributions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed distributions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <CourseAccordion
          title={'Course Project Distribution'}
          SD1={SD1}
          SD2={SD2}
        />
      </Modal>
    </>
  );
};

// Displays Project Count Distribution by Department
const DepartmentStatsWidget = () => {
  const [open, setOpen] = useState(false);
  const [distribution, setDistribution] = useState({
    CIS: 0,
    ECE: 0,
    IMSE: 0,
    ME: 0,
  });
  const [CIS, setCIS] = useState([]);
  const [ECE, setECE] = useState([]);
  const [IMSE, setIMSE] = useState([]);
  const [ME, setME] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const test = null;
  const fetchData = async () => {
    try {
      const year = searchParams.get('year') || new Date().getFullYear();

      const response = await getDepartmentDistribution(year);

      const temp = {};
      response?.data?.data?.forEach((element) => {
        temp[element._id] = element.totalProjects;
      });

      setDistribution(temp);

      const projects = await getProjects(objectToQueryString({ year }));
      setCIS(
        projects?.data?.data?.filter(
          (project) => project?.department === 'CIS'
        ) || []
      );
      setECE(
        projects?.data?.data?.filter(
          (project) => project?.department === 'ECE'
        ) || []
      );
      setIMSE(
        projects?.data?.data?.filter(
          (project) => project?.department === 'IMSE'
        ) || []
      );
      setME(
        projects?.data?.data?.filter(
          (project) => project?.department === 'ME'
        ) || []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [searchParams]);
  const data = {
    labels: ['CIS', 'ECE', 'IMSE', 'ME'],
    datasets: [
      {
        label: 'Departments Project Count',
        data: [
          distribution?.CIS || 0,
          distribution?.ECE || 0,
          distribution?.IMSE || 0,
          distribution?.ME || 0,
        ],
        backgroundColor: [
          'rgba(250, 250, 250, 0.2)',
          'rgba(0, 0, 0, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(215, 215, 215, 1)',
          'rgba(0, 0, 0, 1',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Department Project Distribution */}
      <Typography {...StylesForm.text('h6')}>
        Department Project Distribution
      </Typography>
      <Divider />

      {/* Start of Pie Chart: Department Distribution */}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} />
        </Box>
      </Box>

      {/* Detailed Distributions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed distributions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <DepartmentAccordion
          title={'Department Project Distribution'}
          CIS={CIS}
          IMSE={IMSE}
          ECE={ECE}
          ME={ME}
        />
      </Modal>
    </>
  );
};

// Displays Project Link Completion Distribution
const PosterImageWidget = ({ stats }) => {
  const [open, setOpen] = useState(false);
  const [complete, setComplete] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('department'))
        params.department = searchParams?.get('department');
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getProjects(objectToQueryString(params));
      setIncomplete(
        response?.data?.data?.filter((project) =>
          [
            'CIS_Default.png',
            'IMSE_Default.png',
            'ME_Default.png',
            'ECE_Default.png',
          ].includes(project?.posterImage)
        ) || []
      );
      setComplete(
        response?.data?.data?.filter(
          (project) =>
            ![
              'CIS_Default.png',
              'IMSE_Default.png',
              'ME_Default.png',
              'ECE_Default.png',
            ].includes(project?.posterImage)
        ) || []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const data = {
    labels: ['Complete', 'Incomplete'],
    datasets: [
      {
        label: '# of Poster Image Completions',
        data: [
          stats?.data?.totalProjects - stats?.data?.defaultPosterImage,
          stats?.data?.defaultPosterImage,
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Poster Image Widget */}
      <Typography {...StylesForm.text('h6')}>
        Poster Image Submissions
      </Typography>
      <Divider />

      {/* Start of Pie Chart: Poster Image Completions*/}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} />
        </Box>
      </Box>

      {/* Detailed Completions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed completions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <Accordion
          title={'Poster Image Completions'}
          completeList={complete}
          incompleteList={incomplete}
        />
      </Modal>
    </>
  );
};

// Displays Project Link Completion Distribution
const PosterLinkWidget = ({ stats }) => {
  const [open, setOpen] = useState(false);
  const [complete, setComplete] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();
  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('department'))
        params.department = searchParams?.get('department');
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getProjects(objectToQueryString(params));
      setIncomplete(
        response?.data?.data?.filter((project) => project?.posterLink === '') ||
          []
      );
      setComplete(
        response?.data?.data?.filter((project) => project?.posterLink !== '') ||
          []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const data = {
    labels: ['Complete', 'Incomplete'],
    datasets: [
      {
        label: '# of Poster Links Completions',
        data: [
          stats?.data?.totalProjects - stats?.data?.noPosterLink,
          stats?.data?.noPosterLink,
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Poster Link Widget */}
      <Typography {...StylesForm.text('h6')}>Poster PDF Submissions</Typography>
      <Divider />

      {/* Start of Pie Chart: Poster LInk Completions*/}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} />
        </Box>
      </Box>

      {/* Detailed Completions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed completions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <Accordion
          title={'Poster PDF Completions'}
          completeList={complete}
          incompleteList={incomplete}
        />
      </Modal>
    </>
  );
};

// Displays Abstract Completion Distribution
const AbstractWidget = ({ stats }) => {
  const [open, setOpen] = useState(false);
  const [complete, setComplete] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const handleOpen = () => {
    fetchData();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [searchParams] = useSearchParams();

  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('department'))
        params.department = searchParams?.get('department');
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getProjects(objectToQueryString(params));
      setIncomplete(
        response?.data?.data?.filter((project) => project?.abstract === '') ||
          []
      );
      setComplete(
        response?.data?.data?.filter((project) => project?.abstract !== '') ||
          []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const data = {
    labels: ['Complete', 'Incomplete'],
    datasets: [
      {
        label: '# of Abstract Completions',
        data: [
          stats?.data?.totalProjects - stats?.data?.noAbstract,
          stats?.data?.noAbstract,
        ],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      {/* Title: Abstract Widget */}
      <Typography {...StylesForm.text('h6')}>Abstract Submissions</Typography>
      <Divider />
      {/* Start of Pie Chart: Abstract Completions*/}
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={{ width: '70%' }}>
          <Pie data={data} />
        </Box>
      </Box>

      {/* Detailed Completions */}
      <Box
        display={'flex'}
        alignItems={'end'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Button variant={'outlined'} onClick={handleOpen}>
          View detailed completions
        </Button>
      </Box>
      <Modal open={open} handleClose={handleClose}>
        <Accordion
          title={'Abstract Completions'}
          completeList={complete}
          incompleteList={incomplete}
        />
      </Modal>
    </>
  );
};

// Displays Due Dates
const DatesWidget = ({ dates }) => {
  return (
    <>
      {/* Title: Due Dates */}
      <Typography {...StylesForm.text('h6')}>Due Dates</Typography>
      <Divider />
      {/* Start of Info: */}
      {dates && (
        <>
          <Typography {...StylesForm.text('p')}>
            Abstract:
            <strong> {formatDate(dates?.abstract)}</strong>
          </Typography>

          <Typography {...StylesForm.text('p')}>
            Poster Draft:
            <strong> {formatDate(dates?.posterLink)}</strong>
          </Typography>

          <Typography {...StylesForm.text('p')}>
            Poster Final:
            <strong> {formatDate(dates?.posterLinkFinal)}</strong>
          </Typography>

          <Divider />
        </>
      )}
    </>
  );
};

// Displays User Details
const UsersWidget = () => {
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    try {
      const params = {};
      if (searchParams?.get('department'))
        params.department = searchParams?.get('department');
      if (searchParams?.get('year')) params.year = searchParams?.get('year');
      if (searchParams?.get('course'))
        params.course = searchParams?.get('course');

      const response = await getUsers(objectToQueryString(params));
      setUsers(response?.data?.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  return (
    <>
      {/* Title: User Details */}
      <Typography {...StylesForm.text('h6')}>Users (w/Filters)</Typography>
      <Divider />
      {/* Start of Info: */}
      <>
        <Typography {...StylesForm.text('p')}>
          Students:
          <strong>
            {' ' + users?.filter((user) => user?.role === 'STUDENT')?.length}
            {users?.filter((user) => user?.role === 'STUDENT')?.length > 1
              ? ' Users'
              : ' User'}
          </strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Faculty:
          <strong>
            {' ' + users?.filter((user) => user.role === 'FACULTY')?.length}
            {users?.filter((user) => user.role === 'FACULTY')?.length > 1
              ? ' Users'
              : ' User'}
          </strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Judges:
          <strong>
            {' ' + users?.filter((user) => user?.role === 'JUDGE')?.length}
            {users?.filter((user) => user?.role === 'JUDGE')?.length > 1
              ? ' Users'
              : ' User'}
          </strong>
        </Typography>

        <Divider />
      </>
      {/* Link to users */}
      <Box display={'flex'} alignItems={'end'} height={'100%'}>
        <Link
          to={`/admin/dashboard/users?${searchParams.toString()}`}
          style={{ color: '#1976D2', margin: 3 }}
        >
          To view the users, click here
        </Link>
      </Box>
    </>
  );
};

// Displays Class Details
const ClassWidget = ({ stats }) => {
  const [searchParams] = useSearchParams();
  return (
    <>
      {/* Title: Project Details */}
      <Typography {...StylesForm.text('h6')}>Dashboard Filters</Typography>
      <Divider />

      {/* Start of Info: Course, Department, Year, Project Count*/}
      <>
        <Typography {...StylesForm.text('p')}>
          Department:{' '}
          <strong> {searchParams?.get('department') || 'ALL'}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Course: <strong> {searchParams?.get('course') || 'ALL'}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Year: <strong> {searchParams?.get('year') || 'ALL'}</strong>
        </Typography>

        <Typography {...StylesForm.text('p')}>
          Count:
          <strong>
            {' ' + stats?.data?.totalProjects}
            {stats?.data?.totalProjects > 1 ? ' Projects' : ' Project'}
          </strong>
        </Typography>
        <Divider />
      </>

      {/* Link to add poster link */}
      <Box display={'flex'} alignItems={'end'} height={'100%'}>
        <Link to={`#`} style={{ color: '#1976D2', margin: 3 }}>
          To view the student projects, click here
        </Link>
      </Box>
    </>
  );
};

// Page that shows when a project is already created
const AdminDash = () => {
  const [stats, setStats] = useState(null);
  const [config, setConfig] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {};
        if (searchParams?.get('department'))
          params.department = searchParams?.get('department');
        if (searchParams?.get('year')) params.year = searchParams?.get('year');
        if (searchParams?.get('course'))
          params.course = searchParams?.get('course');
        const response = await getStats(objectToQueryString(params));
        setStats(response.data);

        const configResponse = await getMainConfig();
        setConfig(configResponse?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [searchParams]);

  return (
    <>
      <Box {...Styles.mainBox}>
        <Toolbar />

        {/* Container With Max Width = lg breakpoint */}
        {stats && (
          <Container {...Styles.customContainer()}>
            {/*Outer Grid Container */}
            <Grid container spacing={3}>
              {/*Inner Grid Begins*/}

              {/*Inner Grid Item 1: Page Title */}
              <Grid item lg={12} sm={12} xs={12}>
                <Paper {...Styles.dashboardPageWidget()}>Admin Dashboard</Paper>
              </Grid>

              {/*Inner Grid Item 2: Dashboard Filters */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <ClassWidget stats={stats} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 3: Users*/}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <UsersWidget />
                </Paper>
              </Grid>

              {/*Inner Grid Item 4: Abstract Widget */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <AbstractWidget stats={stats} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 5: Project Link */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <PosterLinkWidget stats={stats} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 6: Poster Image */}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <PosterImageWidget stats={stats} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 6b: Due Dates*/}
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paper
                  {...Styles.dashboardContentWidget({ lg: 360, md: 'auto' })}
                >
                  <DatesWidget dates={config?.dueDates} />
                </Paper>
              </Grid>

              {/*Inner Grid Item 7: Project Distribution BY DEPARTMENT */}
              {searchParams.get('year') &&
                !searchParams.get('department') &&
                !searchParams.get('course') && (
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    <Paper
                      {...Styles.dashboardContentWidget({
                        lg: 360,
                        md: 'auto',
                      })}
                    >
                      <DepartmentStatsWidget />
                    </Paper>
                  </Grid>
                )}

              {/*Inner Grid Item 8: Project Distribution BY COURSE  */}
              {searchParams.get('year') &&
                !searchParams.get('department') &&
                !searchParams.get('course') && (
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    <Paper
                      {...Styles.dashboardContentWidget({
                        lg: 360,
                        md: 'auto',
                      })}
                    >
                      <CourseStatsWidget />
                    </Paper>
                  </Grid>
                )}

              {/*End of Inner Grid*/}
            </Grid>
          </Container>
        )}
      </Box>
    </>
  );
};

export default function AdminDashboard() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const params = {};
    if (searchParams.get('department'))
      params.department = searchParams.get('department');
    if (searchParams.get('year')) params.year = searchParams.get('year');
    else params.year = new Date().getFullYear();
    if (searchParams.get('course')) params.course = searchParams.get('course');
    navigate(`/admin/dashboard?${objectToQueryString(params)}`);
  }, [navigate, searchParams]);
  return <Dashboard Page={AdminDash} PageName={'Admin Dashboard'} />;
}
