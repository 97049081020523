function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th'; // handles most numbers between 4 and 20
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

function formatDate(date, showYear = false, useNumericFormat = false) {
  if (!date) return 'TBD';
  date = new Date(date);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  if (useNumericFormat) {
    // Return the date in MM/DD/YYYY format
    return `${String(month + 1).padStart(2, '0')}/${String(day).padStart(
      2,
      '0'
    )}/${year}`;
  }

  if (showYear)
    return `${months[month]} ${day}${getOrdinalSuffix(day)}, ${year}`;

  return `${months[month]} ${day}${getOrdinalSuffix(day)}`;
}

export { formatDate };
