import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { useAuth } from '../../utils/AuthContext.js';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from '../../components/AttendeeNavBar.js';
import { cleanFormatID, formatID } from '../../utils/formatAttributes.js';
import PasswordField from '../../components/Password.js';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" to="/student/signup">
        University of Michigan-Dearborn
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function StudentSignUp() {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    role: 'STUDENT',
    year: new Date().getFullYear(),
    firstPassword: '',
    secondPassword: '',
    department: 'CIS',
    course: 'SD2',
  });
  const { registerStudent, registerError } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    const formSubmit = { ...form };

    if (form.department === 'ME') {
      formSubmit.course = 'SD2';
    }

    const u = await registerStudent({
      ...formSubmit,
      fullName: formSubmit.firstName + ' ' + formSubmit.lastName,
      password: formSubmit.firstPassword,
    });
    if (u) {
      if (u.success && u.data.role === 'STUDENT') {
        if (u.data.projectsOwned.length === 0) {
          navigate('/student/dashboard/project/0');
          return;
        }
        navigate(`/student/dashboard/project/${u.data.projectsOwned[0]._id}`);
        return;
      }
    }
  };

  const invalidInput = () => {
    const temp = { ...form, fullName: 'temp' };

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length > 40
      )
    ) {
      return true;
    }

    if (
      Object.values(temp).some(
        (value) => typeof value === 'string' && value.length === 0
      )
    ) {
      return true;
    }

    if (
      form.firstPassword !== form.secondPassword ||
      form.firstPassword.length === 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <NavBar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Student Sign up
          </Typography>
          {registerError && (
            <Alert severity="error" sx={{ my: 3 }}>
              {registerError}
            </Alert>
          )}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={form.firstName}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={form.lastName}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <form autoComplete="off">
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address (must be umich email)"
                    name="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    autoComplete="new-email"
                  />
                </form>
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  required
                  fullWidth
                  name="password"
                  label="Password (at least 8 characters)"
                  id="password"
                  value={form.firstPassword}
                  onChange={(e) =>
                    setForm({ ...form, firstPassword: e.target.value })
                  }
                  autoComplete="new-password"
                />
              </Grid>

              <Grid item xs={12}>
                <PasswordField
                  required
                  fullWidth
                  name="password"
                  label="Confirm Password"
                  id="password"
                  value={form.secondPassword}
                  onChange={(e) =>
                    setForm({ ...form, secondPassword: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Department
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.department}
                    onChange={(e) =>
                      setForm({ ...form, department: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="CIS"
                      control={<Radio />}
                      label="CIS"
                    />
                    <FormControlLabel
                      value="ECE"
                      control={<Radio />}
                      label="ECE"
                    />
                    <FormControlLabel
                      value="IMSE"
                      control={<Radio />}
                      label="IMSE"
                    />
                    <FormControlLabel
                      value="ME"
                      control={<Radio />}
                      label="ME"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {form.department !== 'ME' && (
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Course
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={form.course}
                      onChange={(e) =>
                        setForm({ ...form, course: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="SD1"
                        control={<Radio />}
                        label="SD1"
                      />
                      <FormControlLabel
                        value="SD2"
                        control={<Radio />}
                        label="SD2"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Are you the team leader? There can only be one team leader
                    in a team. If you are not the team leader, please select
                    "No".
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={form.isTeamLeader}
                    onChange={(e) =>
                      setForm({ ...form, isTeamLeader: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="YES"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="NO"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                {!!form?.isTeamLeader && (
                  <Alert severity="info">
                    {form?.isTeamLeader === 'YES' && (
                      <>
                        <strong>Team Leader</strong> is responsible for creating
                        project, adding members, and managing project. There can
                        only be one team leader in a team.
                      </>
                    )}
                    {form?.isTeamLeader === 'NO' && (
                      <>
                        <strong>Team Member </strong> can only join a project
                        after the team leader has created the project and
                        invited the team member. Make sure you have one team
                        leader in your team.
                      </>
                    )}
                  </Alert>
                )}
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
              disabled={!!invalidInput()}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/student/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
