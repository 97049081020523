import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Typography,
} from '@mui/material';
import { useAuth } from '../utils/AuthContext';
import AdvancedModal from './AdvancedModal';
import { Link } from 'react-router-dom';
import { useFeedback } from '../utils/FeedbackContext';
import { uploadProfileImage, uploadProfileResume } from '../utils/API';

function getColorForRole(role) {
  const colors = {
    ADMIN: '#1F6CFA',
    STUDENT: '#085C25',
    JUDGE: '#673AB7',
    FACULTY: '#1F6CFA',
  };
  return colors[role] || 'red'; // Default color if the role is not found
}

const DetailRow = ({ label, value }) => {
  if (!value) return <></>;

  return (
    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
      <Typography variant="body1" fontFamily={'sans-serif'} fontWeight={600}>
        {label}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

const CardLayout = ({ children, title }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 2,
        borderRadius: '8px',
        boxShadow: 1,
        border: 1,
        bgcolor: 'background.paper',
        borderColor: 'grey.300',
      }}
    >
      {title && (
        <>
          <Typography variant="h6" fontFamily={'sans-serif'} fontWeight={600}>
            {title}
          </Typography>
          <Divider />
        </>
      )}
      {children}
    </Box>
  );
};

const UserProfile = ({
  open = false,
  onClose = () => {},
  title = 'User Profile',
  user,
}) => {
  const { user: me, isLoading } = useAuth();
  const { notifySuccess, notifyError } = useFeedback();

  const selectedUser = user || me;

  const isMe = me.id === selectedUser.id;

  const isStudent = me.role === 'STUDENT';

  const isNotAdmin = me.role !== 'ADMIN';

  const Icon = user?.icon ? (
    <Avatar
      alt={user.fullName}
      src={`${process.env.REACT_APP_SPACES_ENDPOINT_PROFILE_ICONS}${user?.icon}`}
      sx={{ width: 80, height: 80 }}
    />
  ) : (
    <Avatar
      sx={{
        bgcolor: getColorForRole(user.role),
        fontSize: '18px',
      }}
    >
      {(user.firstName[0] + user.lastName[0]).toUpperCase()}{' '}
    </Avatar>
  );

  const resume = selectedUser.resume ? (
    <Link
      to={`${process.env.REACT_APP_SPACES_ENDPOINT_PROFILE_RESUMES}${selectedUser.resume}`}
      target="_blank"
    >
      View Resume
    </Link>
  ) : (
    'No resume uploaded'
  );

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const res = await uploadProfileImage(formData);
      notifySuccess('Profile picture uploaded successfully');
    } catch (error) {
      notifyError('Failed to upload profile picture');
    }
  };

  const handleResumeUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('resume', file);

    try {
      const res = await uploadProfileResume(formData);
      notifySuccess('Resume uploaded successfully');
    } catch (error) {
      notifyError('Failed to upload resume');
    }
  };

  return (
    <AdvancedModal
      open={open}
      title={title}
      handleClose={onClose}
      width={{ lg: 800, md: 600, sm: '90%' }}
      hideSaveButton
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <CardLayout title="User Details">
          <DetailRow label="Name" value={selectedUser.fullName} />
          <DetailRow label="Prefix" value={selectedUser.prefix} />
          <DetailRow label="Email" value={selectedUser.email} />
          <DetailRow label="Role" value={selectedUser.role} />
          <DetailRow label="Department" value={selectedUser.department} />
          <DetailRow label="Course" value={selectedUser.course} />
          <DetailRow label="Year" value={selectedUser.year} />
          <DetailRow label="Interest" value={selectedUser.interest} />
          {isNotAdmin && isMe && (
            <Alert variant="outlined" severity="info" fontFamily={'sans-serif'}>
              If you would like to update your profile, please contact an
              administrator.
            </Alert>
          )}
        </CardLayout>

        <CardLayout title={'Profile Icon & Resume'}>
          <DetailRow label="System Icon" value={Icon} />
          {isMe && (
            <Button variant="outlined" color="primary" component="label">
              Upload Profile Picture{' '}
              <input type="file" hidden onChange={handleUpload} />
            </Button>
          )}
          <DetailRow label="Resume" value={resume} />
          {isMe && isStudent && (
            <Button variant="outlined" color="primary" component="label">
              Upload Resume (PDF)
              <input type="file" hidden onChange={handleResumeUpload} />
            </Button>
          )}
          {isMe && isStudent && (
            <Alert variant="outlined" severity="info" fontFamily={'sans-serif'}>
              Adding your profile picture and resume is not required but can be
              helpful for judges and recruiters to learn more about you and can
              lead to professional opportunities.
            </Alert>
          )}
        </CardLayout>

        {!!selectedUser?.projectsOwned?.length && (
          <CardLayout title="Project Owned">
            {selectedUser?.projectsOwned?.map((project) => (
              <DetailRow
                key={project.id}
                label="Project"
                value={project.title}
              />
            ))}
            {isNotAdmin && isMe && (
              <Alert
                variant="outlined"
                severity="info"
                fontFamily={'sans-serif'}
              >
                If a project is missing or wrong, please contact an
                administrator.
              </Alert>
            )}
          </CardLayout>
        )}
      </Box>
    </AdvancedModal>
  );
};

export default UserProfile;
