import { Box, Divider, Switch, Typography } from '@mui/material';
import { StylesForm } from '../../../styles/global';
import { useAuth } from '../../../utils/AuthContext';
import { updateProjectStatus } from '../../../utils/API';
import { useParams } from 'react-router-dom';
import { useFeedback } from '../../../utils/FeedbackContext';

// Widget for Poster Image
const InformPosterImage = ({ project, dueDate }) => {
  const { user } = useAuth();
  const { pid } = useParams();
  const { notifySuccess, notifyError } = useFeedback();

  if (!project) {
    return null;
  }

  const submittedFormat = !!project?.data?.posterSubmittedBy
    ? `Submitted ${project.data.posterImageStatus} by ${project?.data?.posterSubmittedBy?.name} on ${project?.data?.posterSubmittedBy?.submissionTime}`
    : `Submitted ${project.data.posterImageStatus}`;

  const handleToggleChange = async (e) => {
    let resource = 'IMAGE';
    let posterImageStatus = 'DRAFT';
    if (e.target.checked) {
      posterImageStatus = 'FINAL';
    }

    try {
      const response = await updateProjectStatus(pid, {
        resource,
        posterImageStatus,
      });
      if (response.data.success) {
        notifySuccess(
          response?.data?.message || 'Poster Image Status Updated Successfully'
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      notifyError('Error updating poster image status. Please try again.');
    }
  };

  return (
    <>
      {project && (
        <>
          {/* Project Title + Due Date */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Project Title */}
            <Typography {...StylesForm.text('h6')}>
              {project.data.title.length > 50
                ? `${project.data.title.substring(0, 50)}... Poster Image`
                : `${project.data.title} Poster Image`}
            </Typography>
            <Divider />

            {/* Due Date */}
            {user?.role !== 'STUDENT' ? (
              <Typography
                {...StylesForm.text('p')}
                sx={{
                  marginBottom: 1,
                  color: '#226E93',
                }}
              >
                (due {dueDate || 'unassigned'})
              </Typography>
            ) : (
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography
                  color={
                    project?.data?.posterImageStatus !== 'FINAL'
                      ? '#226E93'
                      : '#D3D3D3'
                  }
                >
                  Draft
                </Typography>
                <Switch
                  checked={project?.data?.posterImageStatus === 'FINAL'}
                  onChange={handleToggleChange}
                />
                <Typography
                  color={
                    project?.data?.posterImageStatus === 'FINAL'
                      ? '#226E93'
                      : '#D3D3D3'
                  }
                >
                  Final
                </Typography>
              </Box>
            )}
          </Box>
          <Divider />

          {/* Image Status */}
          <Typography
            {...StylesForm.text('p')}
            sx={{
              marginBottom: 1,
              color: '#708090',
            }}
          >
            Status:{' '}
            {project.data.posterImage === 'CIS_Default.png' ||
            project.data.posterImage === 'ECE_Default.png' ||
            project.data.posterImage === 'IMSE_Default.png' ||
            project.data.posterImage === 'ME_Default.png'
              ? 'Placeholder Image'
              : submittedFormat}
          </Typography>

          {/* Poster Image *** */}
          <img
            alt="poster"
            src={`${process.env.REACT_APP_SPACES_ENDPOINT_POSTER_IMAGES}${project.data.posterImage}`}
          />
        </>
      )}
    </>
  );
};

export default InformPosterImage;
