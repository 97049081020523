import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';

const Task = ({ title, subtitle, isCompleted, children }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {isCompleted ? (
          <Typography sx={{ color: 'success.main', mr: 2 }}>✓</Typography>
        ) : (
          <Typography sx={{ color: 'text.secondary', mr: 2 }}>O</Typography>
        )}
        <Typography sx={{ width: '36%', flexShrink: 0 }}>{title}</Typography>
        {subtitle && (
          <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Task;
