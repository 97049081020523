import { Alert, Box, Divider, Switch, Typography } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { StylesForm } from '../../../styles/global';
import { useAuth } from '../../../utils/AuthContext';
import { useMemo, useState } from 'react';
import { getMainConfig, updateProjectStatus } from '../../../utils/API';
import { useFeedback } from '../../../utils/FeedbackContext';

// Widget for Poster Link
const InformPosterLink = ({ project, dueDate, showLink = false }) => {
  const { pid } = useParams();
  const { user } = useAuth();

  const [drive, setDrive] = useState(undefined);

  const [searchParams] = useSearchParams();

  const { notifySuccess, notifyError } = useFeedback();

  useMemo(async () => {
    try {
      const response = await getMainConfig();
      setDrive(new Map(response?.data?.data?.googleDriveFolders || []));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [searchParams.toString()]);

  const handleToggleChange = async (e) => {
    let resource = 'LINK';
    let posterLinkStatus = 'DRAFT';
    if (e.target.checked) {
      posterLinkStatus = 'FINAL';
    }

    try {
      const response = await updateProjectStatus(pid, {
        resource,
        posterLinkStatus,
      });
      if (response.data.success) {
        notifySuccess(
          response?.data?.message || 'Poster Link Status Updated Successfully'
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      notifyError('Error updating poster link status');
    }
  };

  return (
    <>
      {project && (
        <>
          {/* Project Title + Due Date */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {/* Project Title */}
            <Typography {...StylesForm.text('h6')}>
              Poster File (.pdf)
            </Typography>
            <Divider />
            {/* Due Date */}
            {user?.role !== 'STUDENT' ? (
              <Typography
                {...StylesForm.text('p')}
                sx={{
                  marginBottom: 1,
                  color: '#226E93',
                }}
              >
                (due {dueDate || 'unassigned'})
              </Typography>
            ) : (
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography
                  color={
                    project?.data?.posterLinkStatus !== 'FINAL'
                      ? '#226E93'
                      : '#D3D3D3'
                  }
                >
                  Draft
                </Typography>
                <Switch
                  checked={project?.data?.posterLinkStatus === 'FINAL'}
                  onChange={handleToggleChange}
                />
                <Typography
                  color={
                    project?.data?.posterLinkStatus === 'FINAL'
                      ? '#226E93'
                      : '#D3D3D3'
                  }
                >
                  Final
                </Typography>
              </Box>
            )}
          </Box>

          <Divider />

          {/* Poster Link */}
          <Link
            {...StylesForm.text('p')}
            to={
              !project.data.posterLink
                ? '#'
                : `${process.env.REACT_APP_SPACES_ENDPOINT_POSTER_FILES}${project.data.posterLink}`
            }
            target="_blank"
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 4,
              color: '#226E93',
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
            }}
          >
            {!project.data.posterLink
              ? 'No poster link submitted.'
              : `${project.data.title}.pdf`}
          </Link>

          {/* Verify Link Works Statement */}
          {user?.role === 'STUDENT' && (
            <Alert severity="info" variant="outlined">
              Please verify that the link works and downloads your submitted
              poster PDF.
            </Alert>
          )}

          {/* Link to add poster link */}
          {showLink && (
            <Box display={'flex'} alignItems={'end'} height={'100%'}>
              <Link
                to={`/student/dashboard/project/${pid}/poster`}
                style={{ color: '#1976D2', margin: 3 }}
              >
                {' '}
                To submit the poster link and image, click here{' '}
              </Link>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default InformPosterLink;
