import axios from 'axios';

// Returns project for given pid
export async function getProject(pid) {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}`, {
    withCredentials: true,
  });
}

// Invites student to project with pid, invite is the email address of the student
export async function inviteStudent(pid, invite) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/owner/user/email/${invite}`,
    {},
    { withCredentials: true }
  );
}

// Update abstract for project pid
export async function updateAbstract(pid, abstract) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/abstract`,
    { abstract: abstract },
    { withCredentials: true }
  );
}

// Update poster link for project pid
export async function updatePosterLink(pid, file) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/poster/link`,
    file,
    { withCredentials: true }
  );
}

// Update Poster link for project pid
export async function updatePosterImage(pid, file) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/poster/image`,
    file,
    { withCredentials: true }
  );
}

// Ask the chatbot a question
export async function askChatbot(message) {
  return axios.put(`${process.env.REACT_APP_DOMAIN}api/v1/chatbot`, {
    question: message.question,
  });
}

// Returns years available for stats based on department
export async function getAvailableYears(department) {
  if (!department)
    return axios.get(
      `${process.env.REACT_APP_DOMAIN}api/v1/project/stats/years`,
      {
        withCredentials: true,
      }
    );
  return axios.get(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/stats/years?department=${department}`,
    {
      withCredentials: true,
    }
  );
}

// Returns years available for stats based on department
export async function getStats(query) {
  return axios.get(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/stats/?${query}`,
    {
      withCredentials: true,
    }
  );
}

// Returns project distrubution based on department
export async function getDepartmentDistribution(year) {
  return axios.get(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/stats/department?year=${year}`,
    {
      withCredentials: true,
    }
  );
}

// Returns project distrubution based on course
export async function getCourseDistribution(year) {
  return axios.get(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/stats/course?year=${year}`,
    {
      withCredentials: true,
    }
  );
}

// Find Projects
export async function getProjects(query) {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/projects?${query}`, {
    withCredentials: true,
  });
}

export function objectToQueryString(params) {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
}

// Find Users
export async function getUsers(query) {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/users?${query}`, {
    withCredentials: true,
  });
}

// Get Main Config
export async function getMainConfig() {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/config/main`, {
    withCredentials: true,
  });
}

// Update Main Config
export async function updateMainConfig(config) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/config/main`,
    config,
    {
      withCredentials: true,
    }
  );
}

// Verify Token Link
export async function verifyTokenLink(token) {
  return axios.get(
    `${process.env.REACT_APP_DOMAIN}api/v1/verify/judge/${token}`,
    {
      withCredentials: true,
    }
  );
}

// Get Invites
export async function getInvites() {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/judge/invites`, {
    withCredentials: true,
  });
}

// Delete an invite
export async function deleteInvite(token) {
  return axios.delete(
    `${process.env.REACT_APP_DOMAIN}api/v1/invite/judge/${token}`,
    {
      withCredentials: true,
    }
  );
}

// Invite a Judge or Faculty
export async function inviteJudgeOrFaculty(form) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/invite/judge`,
    form,
    {
      withCredentials: true,
    }
  );
}

// Get chatbot summary
export async function getChatbotSummary() {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/chatbot/data`, {
    withCredentials: true,
  });
}

// Update Chatbot Knowledge Base
export async function updateKnowledgeBase(data) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/document/read`,
    data,
    {
      withCredentials: true,
    }
  );
}

// Get Tutorials
export async function getTutorials(query) {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/tutorials?${query}`, {
    withCredentials: true,
  });
}

// Get tutorial by tid
export async function getTutorial(tid) {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/tutorials/${tid}`, {
    withCredentials: true,
  });
}

// Create Tutorial
export async function createTutorial(tutorial) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/tutorials`,
    tutorial,
    {
      withCredentials: true,
    }
  );
}

// Upload Image
export async function uploadImage(file) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/image/upload`,
    file,
    {
      withCredentials: true,
    }
  );
}

// Delete Tutorial
export async function deleteTutorial(tid) {
  return axios.delete(
    `${process.env.REACT_APP_DOMAIN}api/v1/tutorials/${tid}`,
    {
      withCredentials: true,
    }
  );
}

// Update Tutorial
export async function updateTutorial(tid, tutorial) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/tutorials/${tid}`,
    tutorial,
    {
      withCredentials: true,
    }
  );
}

// Get Rubrics
export async function getRubrics(query) {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/rubrics?${query}`, {
    withCredentials: true,
  });
}

// Get Rubric by rid
export async function getRubric(rid) {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/rubric/${rid}`, {
    withCredentials: true,
  });
}

// Delete a Rubric
export async function deleteRubric(rid) {
  return axios.delete(`${process.env.REACT_APP_DOMAIN}api/v1/rubric/${rid}`, {
    withCredentials: true,
  });
}

// Create a rubric
export async function createRubric(rubric) {
  console.log(rubric);
  return axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/rubric`, rubric, {
    withCredentials: true,
  });
}

// Update Rubric
export async function updateRubric(rid, rubric) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/rubric/${rid}`,
    rubric,
    {
      withCredentials: true,
    }
  );
}

// Create Judge Assignments
export async function createJudgeAssignments(assignments) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/judge/assignment`,
    assignments,
    {
      withCredentials: true,
    }
  );
}

// Get assignments for a project
export async function getAssignments(pid) {
  return axios.get(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/assignments`,
    {
      withCredentials: true,
    }
  );
}

// Delete assignment by aid
export async function deleteAssignment(aid) {
  return axios.delete(
    `${process.env.REACT_APP_DOMAIN}api/v1/judge/assignment/${aid}`,
    {
      withCredentials: true,
    }
  );
}

// Get my judge assignments
export async function getMyAssignments() {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/judge/assignments`, {
    withCredentials: true,
  });
}

// Submit Scores
export async function submitScores(aid, scores) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/judge/assignment/${aid}`,
    scores,
    {
      withCredentials: true,
    }
  );
}

// Send a message to the project owners
export async function sendMessage(pid, message) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/inbox`,
    message,
    {
      withCredentials: true,
    }
  );
}

// Bid on a project
export async function addBidToProject(pid) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/bid`,
    {},
    {
      withCredentials: true,
    }
  );
}

// unbid on a project
export async function removeBidFromProject(pid) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/unbid`,
    {},
    {
      withCredentials: true,
    }
  );
}

// Update a project station
export async function updateStation(pid, station) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/station`,
    station,
    {
      withCredentials: true,
    }
  );
}

// Get student options
export async function getStudentsOptions(params = {}, studentMode = true) {
  if (studentMode)
    return axios.get(
      `${process.env.REACT_APP_DOMAIN}api/v1/user/students/options`,
      {
        withCredentials: true,
      }
    );

  return axios.get(
    `${
      process.env.REACT_APP_DOMAIN
    }api/v1/user/students/options?${objectToQueryString(params)}`,
    {
      withCredentials: true,
    }
  );
}

// Remove a student by email
export async function removeStudent(pid, uid) {
  return axios.delete(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/owner/user/${uid}`,
    {
      withCredentials: true,
    }
  );
}

// Delete a project by pid
export async function deleteProject(pid) {
  return axios.delete(`${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}`, {
    withCredentials: true,
  });
}

// Update a user by id
export async function updateUser(uid, user) {
  return axios.put(`${process.env.REACT_APP_DOMAIN}api/v1/user/${uid}`, user, {
    withCredentials: true,
  });
}

// Delete a user by id
export async function deleteUser(uid) {
  return axios.delete(`${process.env.REACT_APP_DOMAIN}api/v1/user/${uid}`, {
    withCredentials: true,
  });
}

// Get Reports by type
export async function getReports(type) {
  return axios.get(
    `${process.env.REACT_APP_DOMAIN}api/v1/admin/reports?type=${type}`,
    {
      withCredentials: true,
    }
  );
}

// Create a report by type
export async function createReport(type, report) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/admin/reports/${type}`,
    report,
    {
      withCredentials: true,
    }
  );
}

// Get a page by id
export async function getPage(id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DOMAIN}api/v1/page/${id}`,
      {
        withCredentials: true,
      }
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

// Update a page by id
export async function updatePage(id, page) {
  return axios.put(`${process.env.REACT_APP_DOMAIN}api/v1/page/${id}`, page, {
    withCredentials: true,
  });
}

// Verify a student email
export async function verifyStudentEmail(email) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/student/verify/${email}`,
    {},
    {
      withCredentials: true,
    }
  );
}

// Forgot a password
export async function forgotPassword(email) {
  return axios.post(
    `${process.env.REACT_APP_DOMAIN}api/v1/password/forgot`,
    { email: email },
    {
      withCredentials: true,
    }
  );
}

// Reset a password
export async function resetPassword(token, email, password) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/password/reset/${token}`,
    { password: password, email: email },
    {
      withCredentials: true,
    }
  );
}

// Accept an invite to a project
export async function acceptInvite(pid) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/join`,
    {},
    {
      withCredentials: true,
    }
  );
}

// Decline an invite to a project by pid
export async function declineInvite(pid) {
  return axios.delete(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/join`,
    {
      withCredentials: true,
    }
  );
}

// Get my project invites
export async function getProjectInvites() {
  return axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/project/invites`, {
    withCredentials: true,
  });
}

// Upload Profile Image
export async function uploadProfileImage(file) {
  return axios.put(`${process.env.REACT_APP_DOMAIN}api/v1/profile/icon`, file, {
    withCredentials: true,
  });
}

// Upload Profile Resume
export async function uploadProfileResume(file) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/profile/resume`,
    file,
    {
      withCredentials: true,
    }
  );
}

// Update a project status
export async function updateProjectStatus(pid, status) {
  return axios.put(
    `${process.env.REACT_APP_DOMAIN}api/v1/project/${pid}/status`,
    { ...status },
    {
      withCredentials: true,
    }
  );
}
