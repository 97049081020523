import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useFeedback } from './FeedbackContext';

const useRedirectUnauth = (...roles) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { notifyError } = useFeedback();

  const handleAuth = useCallback(() => {
    if (!user) {
      notifyError('You must be logged in to access this page');
      navigate('/');
    } else if (roles.length > 0 && !roles.includes(user.role)) {
      notifyError('You are not authorized to access this page');
      navigate('/');
    }
  }, [user, roles, navigate, notifyError]);

  useEffect(() => {
    handleAuth();
  }, [user, handleAuth]);

  return null;
};

export default useRedirectUnauth;
